import * as React from 'react';
import type { HeadFC } from 'gatsby';
import '../styles/index.css';
import NavigationSpacer from '../components/NavigationSpacer';

const IndexPage = () => {
  return (
    <main style={{ width: '100%', height: '100%' }}>
      <NavigationSpacer />
      <div style={{ textAlign: 'center' }}>
        <h2>Angaben zum Anbieter gemäß § 5 TMG</h2>
        <p>
          Patrick Felber
          <br />
          Thürachstr. 13
          <br />
          79189 Bad Krozingen
          <br />
          Deutschland
        </p>

        <h2>Kontakt</h2>
        <p>
          E-Mail: info@patrick-felber.de
          <br />
          Telefon: +49 7633 9219846
        </p>

        <h2>Verantwortlich gemäß § 18 Abs. 2 MStV</h2>
        <p>
          Patrick Felber
          <br />
          Thürachstr. 13
          <br />
          79189 Bad Krozingen
          <br />
          Deutschland
        </p>

        <p>
          Plattform der EU zur außergerichtlichen Online-Streitbeilegung:{' '}
          <a href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a>.<br /> Wir sind weder
          bereit noch verpflichtet, an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
          teilzunehmen.
        </p>
      </div>
    </main>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <title>Imprint</title>;
